import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Axios from 'axios';
import Header from './Header';
import { copyToClipBoard } from "../../utils/copyToClipBoard";
import * as formValidation from '../../utils/formValidation';
import Pagination from './Pagination';
import axiosInstance from '../../utils/axiosInstance';

export default function ProNetworkDetails() {
    const navigate = useNavigate();
    const { userId } = useParams();
    const [proDetails, setProDetails] = useState(null);
    const [proSubmissions, setProSubmissions] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [limit] = useState(10);

    useEffect(() => {
        if (userId) {
            getProDetails(userId);
            getProSubmissions(userId, currentPage);
        }
    }, [userId, currentPage]);

    const getProDetails = (userId) => {
        setShowLoader(true);
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/get-pro-details`, {
            userId: userId
        }).then((response) => {
            setProDetails(response.data[0]);
            setShowLoader(false);
        }).catch(function (error) {
            setShowLoader(false);
            console.log(error);
        });
    };

    const getProSubmissions = (userId, page = 1) => {
        setShowLoader(true);
        axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/api/pros/get-pro-submissions`, {
            userId: userId,
            page: page,
            limit: limit
        }).then((response) => {
            setProSubmissions(response.data.data);
            setTotalItems(response.data.total);
            setShowLoader(false);
        }).catch(function (error) {
            setShowLoader(false);
            console.log(error);
        });
    };

    const handleCopyToClipboard = (text) => {
        copyToClipBoard(text);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        getProSubmissions(userId, newPage);
    };

    return (
        <div id="fazzad-site-container">
            <Header mode='default' />
            <main id="fazzad-main">
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                            <li>
                                <a href='' onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/survey')
                                }}>Home</a>
                                <span className="separator">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </li>
                            <li>
                                <a href='' onClick={(e) => {
                                    e.preventDefault();
                                    navigate('/mynetwork')
                                }}>My network of Pros & Vendors</a>
                                <span className="separator">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </li>
                            <li aria-current="page">
                                <span>{proDetails?.firstName} {proDetails?.lastName}</span>
                            </li>
                        </ol>
                    </nav>

                    {proDetails && (
                        <>
                            <div id="page-title">
                                <h1 className="h3">{proDetails.firstName} {proDetails.lastName}</h1>
                            </div>
                            <div className="pro-vendor__container fz-block">
                                <div className="pro-vendor">
                                    <div className="content">
                                        <div className="inline">
                                            <div className="inline-title">
                                                <strong>{proDetails.company}</strong>
                                            </div>
                                            <div className="inline-buttons">
                                                <p>{proDetails.license}</p>
                                            </div>
                                        </div>
                                        <div className="inline">
                                            <div className="inline-title">
                                                <p>
                                                    {proDetails.address}
                                                    <button 
                                                        title="Copy" 
                                                        className="button button-icon button-xs"
                                                        onClick={() => handleCopyToClipboard(proDetails.address)}
                                                    >
                                                        <span className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5 15C4.06812 15 3.60218 15 3.23463 14.8478C2.74458 14.6448 2.35523 14.2554 2.15224 13.7654C2 13.3978 2 12.9319 2 12V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H12C12.9319 2 13.3978 2 13.7654 2.15224C14.2554 2.35523 14.6448 2.74458 14.8478 3.23463C15 3.60218 15 4.06812 15 5M12.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V12.2C22 11.0799 22 10.5198 21.782 10.092C21.5903 9.71569 21.2843 9.40973 20.908 9.21799C20.4802 9 19.9201 9 18.8 9H12.2C11.0799 9 10.5198 9 10.092 9.21799C9.71569 9.40973 9.40973 9.71569 9.21799 10.092C9 10.5198 9 11.0799 9 12.2V18.8C9 19.9201 9 20.4802 9.21799 20.908C9.40973 21.2843 9.71569 21.5903 10.092 21.782C10.5198 22 11.0799 22 12.2 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </p>
                                                <p>
                                                    {proDetails.website && (
                                                        <a href={proDetails.website} target="_blank" rel="noopener noreferrer">
                                                            {proDetails.website}
                                                        </a>
                                                    )}
                                                    {formValidation.formatPhoneNumber(proDetails.phone)}
                                                    <button 
                                                        title="Copy" 
                                                        className="button button-icon button-xs"
                                                        onClick={() => handleCopyToClipboard(proDetails.phone)}
                                                    >
                                                        <span className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5 15C4.06812 15 3.60218 15 3.23463 14.8478C2.74458 14.6448 2.35523 14.2554 2.15224 13.7654C2 13.3978 2 12.9319 2 12V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H12C12.9319 2 13.3978 2 13.7654 2.15224C14.2554 2.35523 14.6448 2.74458 14.8478 3.23463C15 3.60218 15 4.06812 15 5M12.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V12.2C22 11.0799 22 10.5198 21.782 10.092C21.5903 9.71569 21.2843 9.40973 20.908 9.21799C20.4802 9 19.9201 9 18.8 9H12.2C11.0799 9 10.5198 9 10.092 9.21799C9.71569 9.40973 9.40973 9.71569 9.21799 10.092C9 10.5198 9 11.0799 9 12.2V18.8C9 19.9201 9 20.4802 9.21799 20.908C9.40973 21.2843 9.71569 21.5903 10.092 21.782C10.5198 22 11.0799 22 12.2 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                    {proDetails.email}
                                                    <button 
                                                        title="Copy" 
                                                        className="button button-icon button-xs"
                                                        onClick={() => handleCopyToClipboard(proDetails.email)}
                                                    >
                                                        <span className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5 15C4.06812 15 3.60218 15 3.23463 14.8478C2.74458 14.6448 2.35523 14.2554 2.15224 13.7654C2 13.3978 2 12.9319 2 12V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H12C12.9319 2 13.3978 2 13.7654 2.15224C14.2554 2.35523 14.6448 2.74458 14.8478 3.23463C15 3.60218 15 4.06812 15 5M12.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V12.2C22 11.0799 22 10.5198 21.782 10.092C21.5903 9.71569 21.2843 9.40973 20.908 9.21799C20.4802 9 19.9201 9 18.8 9H12.2C11.0799 9 10.5198 9 10.092 9.21799C9.71569 9.40973 9.40973 9.71569 9.21799 10.092C9 10.5198 9 11.0799 9 12.2V18.8C9 19.9201 9 20.4802 9.21799 20.908C9.40973 21.2843 9.71569 21.5903 10.092 21.782C10.5198 22 11.0799 22 12.2 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-container" style={{ marginBottom: '2rem' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Projects contacted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {proSubmissions.map((submission, index) => (
                                            <tr key={index}>
                                                <td className="fz-break-all">
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(`/survdetails/${submission.packageName}`, '_blank');
                                                    }}>
                                                        {submission.packageAddress}
                                                    </a>
                                                    <button 
                                                        title="Copy" 
                                                        className="button button-icon button-xs"
                                                        onClick={() => handleCopyToClipboard(submission.packageAddress)}
                                                    >
                                                        <span className="icon">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5 15C4.06812 15 3.60218 15 3.23463 14.8478C2.74458 14.6448 2.35523 14.2554 2.15224 13.7654C2 13.3978 2 12.9319 2 12V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H12C12.9319 2 13.3978 2 13.7654 2.15224C14.2554 2.35523 14.6448 2.74458 14.8478 3.23463C15 3.60218 15 4.06812 15 5M12.2 22H18.8C19.9201 22 20.4802 22 20.908 21.782C21.2843 21.5903 21.5903 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V12.2C22 11.0799 22 10.5198 21.782 10.092C21.5903 9.71569 21.2843 9.40973 20.908 9.21799C20.4802 9 19.9201 9 18.8 9H12.2C11.0799 9 10.5198 9 10.092 9.21799C9.71569 9.40973 9.40973 9.71569 9.21799 10.092C9 10.5198 9 11.0799 9 12.2V18.8C9 19.9201 9 20.4802 9.21799 20.908C9.40973 21.2843 9.71569 21.5903 10.092 21.782C10.5198 22 11.0799 22 12.2 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <Pagination
                                currentPage={currentPage}
                                totalItems={totalItems}
                                itemsPerPage={limit}
                                onPageChange={handlePageChange}
                            />
                        </>
                    )}
                </div>
            </main>
        </div>
    );
} 
