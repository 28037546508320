/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef, useContext} from 'react';
import * as formValidator from '../../utils/formValidation';
import {cleanMediaUrl} from '../../utils/clearMediaUrl';
import {dateField} from '../../utils/dateFormat';
import {S3} from "@aws-sdk/client-s3";
import {Upload} from "@aws-sdk/lib-storage";
import {useNavigate, useParams} from 'react-router-dom';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import BreadCrumbs from '../libs/BreadCrumbs';
import checkUserProfile from '../../utils/checkUserProfile';
import getSvg from '../../utils/getSvg';
import Header from '../libs/Header';
import JobTags from '../libs/JobTags';
import MailingAddressForm from '../libs/MailingAddressForm';
import PageTitleWithSteps from '../libs/PageTitleWithSteps';
import S31 from "react-aws-s3";
import useGooglePlaceAutoComplete from "../../service/google_place_autocomplete";
import {getPutObjectSignedUrl} from "../../utils/awsHelpers";
import UploadSection from '../libs/UploadSection';
import UploadingFilesSection from '../libs/UploadingFilesSection';
import {loadGoogleMapsAPI} from '../../utils/loadGoogleMapsAPI';
import AppContext from '../../AppContext';
import PageTitle from "../libs/PageTitle";
import VideoModal from "../libs/VideoModal";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function CreateNewPackage() {
    const navigate = useNavigate();
    const {userInfo, setUserInfo} = useContext(AppContext);
    const params = useParams();
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [countScopeOfWorkVideoIndex, setCountScopeOfWorkVideoIndex] = useState(0);
    const [coverErrorFileUploaded, setCoverErrorFileUploaded] = useState([]);
    const [coverErrorMessage, setCoverErrorMessage] = useState(false);
    const [coverFormatMessage, setCoverFormatMessage] = useState(false);
    const [coverImageFileName, setCoverImageFileName] = useState(null);
    const [currentCoverImageIndex, setCurrentCoverImageIndex] = useState(0);
    const [currentDwgIndex, setCurrentDwgIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
    const [currentStillPictureIndex, setCurrentStillPictureIndex] = useState(0);
    const [currentThreeSixtyIndex, setCurrentThreeSixtyIndex] = useState(0);
    const [dropBoxLink, setDropBoxLink] = useState('');
    const [droneErrorFileUploaded, setDroneErrorFileUploaded] = useState([]);
    const [dwgErrorFileUploaded, setDwgErrorFileUploaded] = useState([]);
    const [fileProgressScopeOfWork, setFileProgressScopeOfWork] = useState(0);
    const [hasCoverImage, setHasCoverImage] = useState(0);
    const [has360Pictures, setHas360Pictures] = useState(0);
    const [hasAddress, setHasAddress] = useState(true);
    const [hasCity, setHasCity] = useState(true);
    const [hasDronePictures, setHasDronePictures] = useState(0);
    const [hasDropBoxLink, setHasDropBoxLink] = useState(true);
    const [hasDwg, setHasDwg] = useState(0);
    const [hasEmail, setHasEmail] = useState(true);
    const [hasPdfs, setHasPdfs] = useState(0);
    const [hasScopeOfWork, setHasScopeOfWork] = useState(true);
    const [hasScopeOfWorkVideo, setHasScopeOfWorkVideo] = useState(true);
    const [hasStartDate, setHasStartDate] = useState(true);
    const [hasState, setHasState] = useState(true);
    const [hasStillPictures, setHasStillPictures] = useState(0);
    const [hasZip, setHasZip] = useState(true);
    const [homeOwnerEmail, setHomeOwnerEmail] = useState('');
    const [isPackageCreated, setIsPackageCreated] = useState(false);
    const [isValidScopeVideo, setIsValidScopeVideo] = useState(true);
    const [packageId, setPackageId] = useState('');
    const [notEnoughtText, setNotEnoughtText] = useState(false);
    const [packagePrivate, setPackagePrivate] = useState(false);
    const [packageInfo, setPackageInfo] = useState(null);
    const [packageName, setPackageName] = useState(params.packageName);
    const [pdfErrorFileUploaded, setPdfErrorFileUploaded] = useState([]);
    const [projectType, setProjectType] = useState(2); // Default to 'residential'
    const [scopeOfWork, setScopeOfWork] = useState('');
    const [scopeOfWorkErrorMessage, setScopeOfWorkErrorMessage] = useState(false);
    const [scopeOfWorkErrorFileUpload, setScopeOfWorkErrorFileUpload] = useState([]);
    const [selectedFileCoverImage, setSelectedFileCoverImage] = useState([]);
    const [selectedFileDrone, setSelectedFileDrone] = useState([]);
    const [selectedDwg, setSelectedDwg] = useState([]);
    const [selectedFilePdf, setSelectedFilePdf] = useState([]);
    const [selectedFileScopeOfWork, setSelectedFileScopeOfWork] = useState([]);
    const [selectedFileScopeOfWorkName, setSelectedFileScopeOfWorkName] = useState('');
    const [selectedFileStillPicture, setSelectedFileStillPicture] = useState([]);
    const [selectedFileThreeSixty, setSelectedFileThreeSixty] = useState([]);
    const [selectedTags, setSelectedTags] = useState({design: [], construction: [],});
    const [showNoFundsMessage, setShowNoFundsMessage] = useState(false);
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showWarningContainerMessage, setShowWarningContainerMessage] = useState(false);
    const [showWrongImageFormat, setShowWrongImageFormat] = useState(false);
    const [isTooLongVideo, setIsTooLongVideo] = useState(false);
    const [isTooLongImage, setIsTooLongImage] = useState(false);
    const [startDate, setStartDate] = useState(dateField(new Date()));
    const [state, setState] = useState('');
    const [stillPictureErrorFileUploaded, setStillPictureErrorFileUploaded] = useState([]);
    const [threeSixtyPictureErrorFileUploaded, setThreeSixtyPictureErrorFileUploaded] = useState([]);
    const [uploadStarted, setUploadStarted] = useState(false);
    const [zip, setZip] = useState('');
    const addressRef = useRef();
    const cadExtensionsRegex = /\.(dwg)$/i;
    const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
    const imageExtensionsRegex = /\.(jpg|jpeg|png)$/i;
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const maxLength = 1000;
    const minLength = 50;
    const pdfExtensionsRegex = /\.(pdf)$/i;
    const transactionCode = sessionStorage.getItem('transactionCode');
    const userId = sessionStorage.getItem('userId');
    const userType = parseInt(sessionStorage.getItem('userType'));
    const userTypeToBeChecked = parseInt(process.env.REACT_APP_SURVEYOR_USER_TYPE_TO_BE_CHECKED);
    const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;
    sessionStorage.setItem('isPropertyOwner', 'false');
    sessionStorage.setItem('userType', userType);
    let autoComplete = "";

    const errorFileArrays = [
        droneErrorFileUploaded,
        threeSixtyPictureErrorFileUploaded,
        stillPictureErrorFileUploaded,
        pdfErrorFileUploaded,
        dwgErrorFileUploaded,
        coverErrorFileUploaded,
        scopeOfWorkErrorFileUpload,
    ];

    const [showModal, setShowModal] = React.useState(false);

    const handleShowVideoModal = () => {
        setShowModal(true);
    };

    const handleCloseVideoModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        sessionStorage.setItem('folderStructure_Still Pictures', JSON.stringify([]));
        sessionStorage.setItem('folderStructure_360 Pictures', JSON.stringify([]));
        getUserProfile();

        async function initGooglePlaces() {
            try {
                await loadGoogleMapsAPI('initGoogleMaps');
                autoComplete = await googleAutoCompleteSvc.initAutoComplete(addressRef.current, handleAddressSelect);
            } catch (error) {
                console.error('Error loading Google Maps API:', error);
            }
        }

        initGooglePlaces();
    }, []);

    const isTagSelected = (value, category) => {
        return selectedTags[category]?.includes(value);
    };

    const handleProjectTypeChange = (event) => {
        setProjectType(event.target.value);
    };

    const getUserProfile = async () => {
        getSurvFeeds(userId);
        setHasEmail(true);
        setHasAddress(true);
        setHasCity(true);
        setHasZip(true);
        setHasState(true);
        setHasScopeOfWork(true);
        setHasDropBoxLink(true);
        setHasStartDate(true);
    }

    const getSurvFeeds = (userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/allpackages`, {
            userId: userId,
            userType: userType
        }).then((response) => {
            if (response.data.length > 0) {
                setPackageInfo(`${response.data[0].packageId}/${response.data[0].packageName}`);
            } else {
                return;
            }
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const toggleCreditCardModal = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/checkForOpenBalance`, {
            userId: userId,
            serviceType: 2
        }).then((response) => {
            if (response.data.length > 0) {
                setShowNoFundsMessage(false);
            } else {
                getSurvFeeds(userId);
                // setShowNoFundsMessage(true);
            }
        }).catch(function (error) {
            console.log('Error checking for balance = ', error);
        })
    };

    const add365Days = () => {
        const today = new Date();
        const nextYear = new Date(today);
        nextYear.setDate(today.getDate() + 365);
        return nextYear;
    }

    const handleAddressSelect = async () => {
        let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
        addressRef.current.value = addressObj.address1.replace('undefined', '');
        setAddress(addressObj.address1);
        setCity(addressObj.locality);
        setState(addressObj.adminArea1Short);
        setZip(addressObj.postalCode);
        setHasAddress(true);
        setHasCity(true);
        setHasState(true);
        setHasZip(true);
    };

    const config = {
        bucketName: process.env.REACT_APP_S3_BUCKET,
        region: process.env.REACT_APP_REGION,
    };


    const updateScopeOfWorkVideo = (fileName, packageName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateScopeOfWorkVideo`, {
            packageName: packageName,
            videoUrl: fileName
        }).then(() => {
            return;
        });
    }

    const uploadVideos = async (file, bucketName, packageName) => {
        try {
            setUploadStarted(true); // Assuming this is to indicate the start of the upload
            const fileName = cleanMediaUrl(file.name);
            const key = `${packageName}/scope-of-work-videos/${fileName}`;
            const signedUrl = await getPutObjectSignedUrl(bucketName, key, file.type);

            if (!signedUrl) {
                throw new Error("cannot generate signed url");
            }

            const targetVideo = {
                Bucket: bucketName,
                Key: key,
                Body: file
            };

            const xhr = new XMLHttpRequest();

            xhr.open('PUT', signedUrl, true);
            // Set the appropriate headers
            xhr.setRequestHeader('Content-Type', file.type);

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    const myTotal = progress.toString().substring(0, 4);
                    setFileProgressScopeOfWork(myTotal);
                }
            };

            xhr.send(file);

            xhr.onload = function () {
                if (xhr.status === 200) {
                    console.log('Request completed successfully:', xhr.responseText);
                    updateScopeOfWorkVideo(fileName, packageName);

                    setTimeout(() => {
                        sendHomeOwnerPackageCreated(packageId, homeOwnerEmail, has360Pictures, hasDronePictures, hasStillPictures, hasPdfs, hasDwg, transactionCode, packageName, hasCoverImage, hasScopeOfWorkVideo);
                        navigate(`/createpackagefolderspictures/${packageName}`)
                        // navigate(`/packageMedia/${packageName}`)
                    }, 5000);
                } else {
                    console.error('Request failed with status:', xhr.status);
                    setUploadStarted(false);
                }
            };

            /* const videoUpload = new Upload({
                 client: new S3({ region: process.env.REACT_APP_REGION }),
                 leavePartsOnError: false,
                 params: targetVideo,
                 url: signedUrl
             });
 
             videoUpload.on("httpUploadProgress", (videoProgress) => {
                 const total = (videoProgress.loaded * 100) / videoProgress.total;
                 const myTotal = total.toString().substring(0, 4);
                 setFileProgressScopeOfWork(myTotal);
             });
 
             await videoUpload.done();
             updateScopeOfWorkVideo(fileName, packageName);*/
        } catch (error) {
            setUploadStarted(false);
            console.error('Upload error:', error);
        } finally {
            setCountScopeOfWorkVideoIndex(1);
            setFileProgressScopeOfWork(100);
            setShowProcessing(true);

            // setTimeout(() => {
            //     sendHomeOwnerPackageCreated(packageId, homeOwnerEmail, has360Pictures, hasDronePictures, hasStillPictures, hasPdfs, hasDwg, transactionCode, packageName, hasCoverImage, hasScopeOfWorkVideo);
            //     navigate(`/packageMedia/${packageName}`)
            // }, 5000);
        }
    }

    async function uploadFileWithPresignedUrl(file, key, bucketName) {
        try {
            const preSignedUrl = await getPutObjectSignedUrl(bucketName, key, file.type);

            if (!preSignedUrl) {
                throw new Error("cannot generate signed url");
            }

            const xhr = new XMLHttpRequest();

            xhr.open('PUT', preSignedUrl, true);
            // Set the appropriate headers
            xhr.setRequestHeader('Content-Type', file.type);

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    const myTotal = progress.toString().substring(0, 4);
                    console.log(myTotal);
                }
            };

            xhr.send(file);

            // Handle errors
            xhr.onerror = function (ev) {
                console.error('Error uploading file.', ev);
            };

            xhr.onload = function () {
                if (xhr.status === 200) {
                    console.log('Request completed successfully:', xhr.responseText);
                } else {
                    console.error('Request failed with status:', xhr.status);
                    throw new Error(xhr.error);
                }
            };
        } catch (error) {
            console.error('Error uploading file.', error);
        }
    }

    const uploadPromise = async (arr, type, packageName) => {
        try {
            let s3BucketName = 'cover-image';
            let compressedFile = '';
            for (let i = 0; i < arr.length; i++) {
                const file = arr[i];
                compressedFile = file;
                const uploadFileName = cleanMediaUrl(file.name);
                const uploadPath = `${packageName}/${s3BucketName}/${uploadFileName}`;
                try {
                    await uploadFileWithPresignedUrl(compressedFile, uploadPath, config.bucketName);
                    if (type === 'cover') {
                        setCoverImage(cleanMediaUrl(file.name), packageName);
                    }
                } catch (error) {
                    // Handle upload error for this specific image
                    console.error(`Upload failed for ${file.name}: ${error}`);
                }
            }
        } catch (error) {
            console.error(`Batch upload failed: ${error}`);
        }
    };

    const handleFilesChange = (event, type) => {
        const wrongFiles = [];
        event.preventDefault();
        const files = [...event.target.files];
        files.filter((file) => {
            if (type.toLowerCase() === 'cover') {
                setCoverErrorFileUploaded([]);
            } else {
                setScopeOfWorkErrorFileUpload([]);
            }
            if (!imageExtensionsRegex.test(file.name)) {
                wrongFiles.push(file.name);
            }
            return wrongFiles;
        });
        switch (type.toLowerCase()) {
            case 'cover':
                setCoverImageFileName(event.target.files[0].name);
                setSelectedFileCoverImage(event.target.files);
                setCoverErrorFileUploaded(wrongFiles);
                setHasCoverImage(1);
                if (wrongFiles.length > 0) {
                    setCoverFormatMessage(true);
                } else {
                    setCoverFormatMessage(false);
                }

                const file = event.target.files[0];

                const fileSizeInbytes = file.size;
                const fileSizeInMB = fileSizeInbytes / (1024 * 1024);

                if (fileSizeInMB > 30) {
                    setIsTooLongImage(true);
                } else {
                    setIsTooLongImage(false);
                }
                break;
            default:
                break;
        }
    }

    const changeHandlerScopeOfWork = (event) => {
        event.preventDefault();
        setScopeOfWorkErrorMessage(false);
        setSelectedFileScopeOfWork(event.target.files);
        setScopeOfWorkErrorFileUpload([]);
        setHasScopeOfWorkVideo(1);

        const chosenFile = event.target.files[0].name;
        if (!videoExtensionsRegex.test(chosenFile)) {
            setIsValidScopeVideo(false);
            setScopeOfWorkErrorFileUpload(event.target.files[0].name);
        } else {
            setSelectedFileScopeOfWorkName(chosenFile);
            setIsValidScopeVideo(true);
        }
    }

    const getWrongFileFormatMessage = (type) => {
        let arrayLength = 0;
        let addS = '';
        let isAre = 'is';
        let theyIt = 'It';
        let fileType = 'image';
        let fileFormat = '.jpg, .jpeg, .png';
        switch (type) {
            case 'cover':
                arrayLength = coverErrorFileUploaded.length;
                if (arrayLength > 1) {
                    addS = 's';
                    isAre = 'are';
                    theyIt = 'They';
                }
                break;
            case 'scopeofwork':
                fileFormat = '.mp4, .mov, .avi, .wmv, .flv, .webm';
                fileType = 'video';
                arrayLength = 1;
                if (arrayLength > 1) {
                    addS = 's';
                    isAre = 'are';
                    theyIt = 'They';
                }
                break;
        }

        return `You have selected file${addS} that ${isAre} not a ${fileType}${addS} file${addS}. All files must have the proper format. (${fileFormat})`;
    }

    const handleUpload = async (type, packageName) => {
        try {
            setShowWarningContainerMessage(false);
            switch (type) {
                case 'coverImage':
                    try {
                        if (selectedFileCoverImage.length > 0) {
                            setUploadStarted(true);
                            setCoverErrorMessage(false);
                            for (const image of selectedFileCoverImage) {
                                setCurrentCoverImageIndex(1);
                                await uploadPromise([image], 'cover', packageName);
                            }

                            await handleUpload('scopeofwork', packageName);
                        } else {
                            await handleUpload('scopeofwork', packageName);
                        }
                    } catch (e) {
                        console.error(`Uploading Still picture file error: ${e}`);
                    }
                    break;
                case 'scopeofwork':
                    try {
                        if (selectedFileScopeOfWork.length > 0) {
                            await uploadVideos(selectedFileScopeOfWork[0], process.env.REACT_APP_PROJECT_VIDEO_SOURCE, packageName)
                        } else {
                            sendHomeOwnerPackageCreated(packageId, homeOwnerEmail, has360Pictures, hasDronePictures, hasStillPictures, hasPdfs, hasDwg, transactionCode, packageName, hasCoverImage, hasScopeOfWorkVideo);
                            setTimeout(() => {
                                navigate(`/createpackagefolderspictures/${packageName}`)
                            }, 4000);
                            // navigate(`/packagemedia/${packageName}`)
                        }
                    } catch (e) {
                        console.error(`Uploading Scope of Work Videos file error: ${e}`);
                    }
                    break;
                default:
                    break;
            }
        } catch (e) {
            setUploadStarted(false);
            console.log('error =  ', e);
        }
    }

    const setCoverImage = (fileName, pckName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateCoverImage`, {
            packageName: pckName,
            coverImageUrl: fileName
        }).then(() => {
            return;
        });
    }

    const sendHomeOwnerPackageCreated = (packageId, homeOwnerEmail, has360Pictures, hasDronePictures, hasStillPictures, hasPdfs, hasDwg, transactionCode, packageName, hasCoverImage, hasScopeOfWorkVideo) => {
        try {
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendHomeOwnerPackageCreatedEmail`, {
                packageId: packageId,
                homeOwnerEmail: homeOwnerEmail,
                has360Pictures: has360Pictures,
                hasDronePictures: hasDronePictures,
                hasStillPictures: hasStillPictures,
                hasPdfs: hasPdfs,
                hasDwg: hasDwg,
                transactionCode: transactionCode,
                userId: userId,
                packageName: packageName,
                hasCoverImage: hasCoverImage,
                hasScopeOfWorkVideo: hasScopeOfWorkVideo
            }).then((response) => {
                return;
            });
        } catch (err) {
            console.log('error sending email to property owner when package is created = ', err);
        }
    }


    const setTags = (e, type) => {
        const value = e.target.value;
        const isChecked = e.target.checked;

        setSelectedTags(prevTags => {
            // Create a copy of the current tag array
            const updatedTags = [...prevTags[type]];

            if (isChecked) {
                // Add the value if it's checked and not already present
                if (!updatedTags.includes(value)) {
                    updatedTags.push(value);
                }
            } else {
                // Remove the value if it's unchecked
                const indexToRemove = updatedTags.indexOf(value);
                if (indexToRemove !== -1) {
                    updatedTags.splice(indexToRemove, 1);
                }
            }

            // Return the updated state
            return {
                ...prevTags,
                [type]: updatedTags,
            };
        });
    };

    const checkForm = () => {
        let isValidDropBoxLink = true;
        const validDropBoxLinks = ['www.dropbox.com', 'dropbox.com'];
        let url = 'www.fazzad.com';
        let isValid = true;

        if (dropBoxLink.trim().length > 0) {
            let dropBoxLinkTest = dropBoxLink;
            if (!/^https?:\/\//i.test(dropBoxLinkTest)) {
                dropBoxLinkTest = 'https://' + dropBoxLinkTest;
            }
            url = new URL(dropBoxLinkTest);
            setDropBoxLink(dropBoxLinkTest);
            isValidDropBoxLink = validDropBoxLinks.some(validDomain => url.host.includes(validDomain));
        }
        if (
            city.trim().length === 0 ||
            homeOwnerEmail.trim().length === 0 ||
            scopeOfWork.trim().length === 0 || startDate.trim().length === 0 ||
            zip.trim().length === 0 || zip.trim().length < 5 || state.trim().length === 0 ||
            !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) ||
            !hasScopeOfWork || notEnoughtText ||
            !homeOwnerEmail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || scopeOfWorkErrorMessage
        ) {
            isValid = false;
        }
        if (typeof address !== 'undefined' && typeof address !== null && typeof address !== 'boolean' && address !== '') {
            if (addressRef.current.value.trim().length === 0) {
                isValid = false;
            }
        }

        if (dropBoxLink !== '') {
            if (isValidDropBoxLink) {
                isValid = true;
                setHasDropBoxLink(true);
            } else {
                isValid = false;
                setHasDropBoxLink(false);
            }
        }

        if (isTooLongImage) {
            isValid = false;
        }

        return isValid;
    }

    const checkZip = (zip) => {
        if (zip.trim().length > 0) {
            const isValid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
            if (zip.trim().length < 5) {
                setHasZip(false);
            } else {
                setHasZip(true);
            }
        } else {
            setHasZip(false);
        }
    };

    const checkStartDate = (datestart) => {
        if (datestart.trim().length > 0) {
            const startDateArray = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/.test(datestart);
            if (startDateArray) {
                setHasStartDate(true);
            } else {
                setHasStartDate(false);
            }
        } else {
            setHasStartDate(false);
        }
    }

    const checkDropBoxLink = (link) => {
        if (link.trim().length > 0) {
            if (!/^https?:\/\//i.test(link)) {
                link = 'https://' + link;
            }
            const urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
            const isValid = urlPattern.test(link);
            if (isValid) {
                const url = new URL(link);
                if (url.host !== 'www.dropbox.com') {
                    setHasDropBoxLink(false);
                } else {
                    setHasDropBoxLink(true);
                }
            } else {
                setHasDropBoxLink(false);
            }
        } else {
            setHasDropBoxLink(true);
        }
    }

    const checkHomeOwnerEmail = (email) => {
        if (email.trim().length > 0) {
            const matchEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            if (Array.isArray(matchEmail) && matchEmail.length > 0) {
                setHasEmail(true);
            } else {
                setHasEmail(false);
            }
        } else {
            setHasEmail(false);
        }
    }

    const handleTextChange = (event) => {
        setNotEnoughtText(false);
        const inputText = event.target.value;
        if (inputText.length <= maxLength) {
            setScopeOfWork(inputText);
        }
    };

    const checkAddress = (address) => {
        if (typeof address !== 'undefined' && typeof address !== null) {
            if (addressRef.current.value.trim().length === 0) {
                setHasAddress(false);
            } else {
                setHasAddress(true);
            }
        }
    }

    const checkScopeOfWork = (scopeofwork) => {
        if (scopeofwork.trim().length === 0) {
            setHasScopeOfWork(false);
        } else {
            setHasScopeOfWork(true);
        }
    }

    const handleAlertButtonClick = () => {
        setShowWarningContainerMessage(false);
    };

    const checkCity = (city) => {
        if (city.trim().length === 0) {
            setHasCity(false);
        } else {
            setHasCity(true);
        }
    }
    const checkState = (state) => {
        if (state.trim().length === 0) {
            setHasState(false);
        } else {
            setHasState(true);
        }
    }

    const createNewPackage = () => {
        checkHomeOwnerEmail(homeOwnerEmail);
        checkStartDate(startDate);
        checkCity(city);
        checkState(state);
        checkZip(zip);
        if (dropBoxLink.length > 0) {
            checkDropBoxLink(dropBoxLink)
        } else {
            setHasDropBoxLink(true);
        }
        ;
        checkScopeOfWork(scopeOfWork);
        checkAddress(address);

        if (checkForm()) {
            //const uuids = uuid();
            const packageExpirationDate = add365Days();
            sessionStorage.setItem('packageName', packageName)
            //setPackageName(uuids);
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/createNewPackage`, {
                packageName: packageName,
                tagsDesign: selectedTags.design.toString(),
                tagsConstruction: selectedTags.construction.toString(),
                scopeOfWork: scopeOfWork,
                homeOwnerEmail: homeOwnerEmail,
                packageAddress: addressRef.current.value,
                packageAddress2: address2,
                packageCity: city,
                packageState: state,
                packageZip: zip,
                startDate: startDate,
                userId: userId,
                dropBoxLink: dropBoxLink,
                packagePrivate: packagePrivate,
                packageStatus: 'Published',
                packageExpirationDate: packageExpirationDate,
                userType: userType,
                transactionCode: transactionCode,
                projectType: projectType
            }).then(async (response) => {
                if (typeof response !== 'undefined' && response !== null) {
                    //closeTransaction(transactionCode, 'credit-card');
                    // setIsPackageCreated(true);
                    // window.scrollTo(0, 0);
                    setPackageId(response.data.insertId);
                    await handleUpload('coverImage', packageName);
                } else {
                    setIsPackageCreated(false);
                }
            }).catch(function (error) {
                console.log(error);
                setIsPackageCreated(false);
            })
            // setIsPackageCreated(true);
        }
    }

    const closeTransaction = (transactionCode, type) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/closeOpenTransaction`, {
            transactionCode: transactionCode,
            type: type,
            serviceType: 2
        }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('closeTransaction error = ', error);
        })
    }


    const handlePrivatePackage = (e) => {
        if (e.target.checked) {
            setPackagePrivate(true);
        } else {
            setPackagePrivate(false);
        }
    }

    return (
        <div id="fazzad-site-container">
            <Header/>
            <main id="fazzad-main">
                <div id="app-container">
                    {!isPackageCreated ?
                        <>
                            <BreadCrumbs
                                header1={'Home'}
                                link1={'survey'}
                                header2={'Package Details'}
                                link2={``}
                                isLink1Clickable={true}
                                isLink2Clickable={false}
                                isLink2Navigation={false}
                                header3={''}
                                link3={''}
                                setIsPackageUpdated={setIsPackageCreated}
                            />
                            {/* <section className="form-covers">
                                    <fieldset>
                                        <legend>Cover Picture</legend>
                                        <div className={`form-field file-input ${showWrongImageFormat || coverErrorMessage || coverErrorFileUploaded.length > 0 ? 'error' : ''}`}>
                                            <input type="file" id="cover-picture" className="file-input__input" onChange={(e) => handleFilesChange(e, 'cover')}/>
                                            <label htmlFor="cover-picture" className="button button-m button-tertiary">
                                                {getSvg('upload')}
                                                <span className="text">Choose file&hellip;</span>
                                            </label>
                                            <div className="file-input__filelist">{coverImageFileName}</div>
                                            { showWrongImageFormat || coverErrorFileUploaded.length > 0 && (
                                                <p className="validation-message">{getWrongFileFormatMessage('cover')}</p>
                                            )}
                                           
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <legend>Scope of Work Video</legend>
                                        <div className={`form-field file-input ${!isValidScopeVideo || scopeOfWorkErrorMessage || scopeOfWorkErrorFileUpload.length > 0 ? 'error' : ''}`}>
                                            <input type="file" id="scope-of-work-video" className="file-input__input" onChange={changeHandlerScopeOfWork} />
                                            <label htmlFor="scope-of-work-video" className="button button-m button-tertiary">
                                                {getSvg('upload')}
                                                <span className="text">Choose file&hellip;</span>
                                            </label>
                                            <div className="file-input__filelist">{selectedFileScopeOfWorkName}</div>
                                            {showWrongImageFormat || scopeOfWorkErrorFileUpload.length > 0 && ( 
                                                <p className="validation-message">{getWrongFileFormatMessage('scope')}</p>
                                            )}
                                            
                                        </div>
                                    </fieldset>
                                </section> */}

                            <div className="form-containera" id="create-package">
                                <div className={'fz-fd-row fz-jc-space-between fz-ai-center'}>
                                    <div id="package-title">
                                        <div className="fz-stack">
                                            <h1 className="h3">Create As-built Package</h1>
                                            <p>All fields are required unless marked as optional.</p>
                                        </div>
                                    </div>

                                    <div className="fz-fd-row fz-jc-flex-end">
                                        <a className="fz-info" onClick={() => handleShowVideoModal()}>
                                        <span className="icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                                                    stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                </svg>
                                        </span>
                                            <span className="text">Info</span>
                                        </a>
                                    </div>
                                </div>

                                <section>
                                    <div id="choose">
                                        <div className="form-choose outlined">
                                            <div className="folder-title">
                                                <h3 className="h4">Cover Picture</h3>
                                            </div>
                                            <fieldset>
                                                <legend>Upload Cover Picture</legend>
                                                <div
                                                    className={`form-field file-input ${isTooLongImage ? 'error' : ''}`}>
                                                    <input type="file" id="cover-picture" className="file-input__input"
                                                           accept={'image/*'}
                                                           onChange={(e) => handleFilesChange(e, 'cover')}/>
                                                    <label htmlFor="cover-picture"
                                                           className="button button-m button-tertiary">
                                                        {getSvg('upload')}
                                                        <span className="text">Choose file&hellip;</span>
                                                    </label>
                                                    <div className="file-input__filelist">{coverImageFileName}</div>
                                                    {showWrongImageFormat || coverErrorFileUploaded.length > 0 && (
                                                        <p className="validation-message">{getWrongFileFormatMessage('cover')}</p>
                                                    )}

                                                    {isTooLongImage && (
                                                        <p className="validation-message">Image file size exceeds the
                                                            maximum limit. Please choose a smaller image.</p>
                                                    )}
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="form-choose outlined">
                                            <div className="folder-title">
                                                <h3 className="h4">Scope of Work video</h3>
                                            </div>
                                            <fieldset>
                                                <legend>Upload video</legend>
                                                <div className="form-field file-input">
                                                    <input type="file" id="scope-of-work-video"
                                                           className="file-input__input"
                                                           onChange={changeHandlerScopeOfWork}/>
                                                    <label htmlFor="scope-of-work-video"
                                                           className="button button-m button-tertiary">
                                                        {getSvg('upload')}
                                                        <span className="text">Choose file&hellip;</span>
                                                    </label>
                                                    <div
                                                        className="file-input__filelist">{selectedFileScopeOfWorkName}</div>
                                                    {showWrongImageFormat || scopeOfWorkErrorFileUpload.length > 0 && (
                                                        <p className="validation-message">{getWrongFileFormatMessage('scope')}</p>
                                                    )}
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                </section>
                                <form action="" autoComplete="off">
                                    <section className="form-details">
                                        <h2 className="h4">Details</h2>
                                        <div className="form-row col-2">
                                            <div className={`form-field text-input ${!hasStartDate ? 'error' : ''}`}>
                                                <label htmlFor="start">Start Date</label>
                                                <input
                                                    type='date'
                                                    id='start'
                                                    name='startDate'
                                                    value={startDate}
                                                    onFocus={(e) => {
                                                        setHasStartDate(true);
                                                    }} onChange={(e) => {
                                                    setStartDate(e.target.value);
                                                }}
                                                />
                                                {!hasStartDate ?
                                                    <p className="validation-message">Enter a valid date</p>
                                                    : ''}
                                            </div>
                                            <div className={`form-field text-input ${!hasEmail ? 'error' : ''}`}>
                                                <label htmlFor="property-owner-email">Property Owner E-mail
                                                    Address</label>
                                                <input
                                                    id='property-owner-email'
                                                    value={homeOwnerEmail}
                                                    className='fz-input'
                                                    autoComplete="one-time-code"
                                                    type='email'
                                                    required
                                                    onFocus={(e) => {
                                                        setHasEmail(true);
                                                    }} onChange={(e) => {
                                                    setHomeOwnerEmail(e.target.value);
                                                }}
                                                />
                                                {!hasEmail ?
                                                    <p className="validation-message">Enter a valid email</p>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        <MailingAddressForm
                                            setAddress={setAddress}
                                            addressRef={addressRef}
                                            hasAddress={hasAddress}
                                            setHasAddress={setAddress}
                                            setAddress2={setAddress2}
                                            address2={address2}
                                            city={city}
                                            setCity={setCity}
                                            hasCity={hasCity}
                                            setHasCity={setHasCity}
                                            state={state}
                                            setState={setState}
                                            hasState={hasState}
                                            setHasState={setHasState}
                                            zip={zip}
                                            setHasZip={setHasZip}
                                            hasZip={hasZip}
                                            setZip={setZip}
                                            formValidator={formValidator}
                                            showHeader={false}
                                            isNewPackage={true}
                                        />
                                        <div className={`form-field text-area ${!hasScopeOfWork ? 'error' : ''}`}>
                                            <label htmlFor="scope-of-work">Scope of Work</label>
                                            <div className="input-container">
                                                <textarea
                                                    name='Scope of work'
                                                    id='scope-of-work'
                                                    value={scopeOfWork}
                                                    maxLength={maxLength}
                                                    aria-required='true'
                                                    onBlur={(e) => {
                                                        setScopeOfWork(formValidator.clearEmojis(e.target.value));
                                                    }}
                                                    onFocus={(e) => {
                                                        setHasScopeOfWork(true);
                                                    }}
                                                    onChange={(e) => {
                                                        {
                                                            handleTextChange(e)
                                                        }
                                                        ;
                                                    }}
                                                >
                                                </textarea>
                                            </div>
                                            {notEnoughtText ?
                                                <p className="validation-message">Enter at least {minLength} characters
                                                    for the scope of work.</p>
                                                : ''
                                            }
                                            {!hasScopeOfWork ?
                                                <p className="validation-message">Enter the scope of work.</p>
                                                : ''
                                            }
                                        </div>
                                        <div className="form-row col-2">
                                            <div className={`form-field text-input ${!hasDropBoxLink ? 'error' : ''}`}>
                                                <label htmlFor="dropbox">Dropbox Link (optional)</label>
                                                <input
                                                    className='fz-input'
                                                    value={dropBoxLink}
                                                    name='dropBoxLink'
                                                    id='dropbox'
                                                    type='text'
                                                    aria-required='true'
                                                    onBlur={(e) => {
                                                        setDropBoxLink(formValidator.clearEmojis(e.target.value));
                                                    }}
                                                    onFocus={(e) => {
                                                        setHasDropBoxLink(true);
                                                    }}
                                                    onChange={(e) => {
                                                        setDropBoxLink(e.target.value);
                                                    }}
                                                />
                                                {!hasDropBoxLink ?
                                                    <p className="validation-message">Enter valid dropbox link or leave
                                                        it blank.</p>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        <section className='form-details'>
                                            <h2 className="h4">Project type</h2>
                                            <div className="form-row col-2">
                                                <div className="form-radio">
                                                    <div className="form-field radio">
                                                        <input
                                                            type="radio"
                                                            name="projectType"
                                                            id="residentialRadio" // Unique id
                                                            value="2" // Value representing this option
                                                            checked={parseInt(projectType) === 2} // Check against value
                                                            onChange={handleProjectTypeChange} // Call the function
                                                        />
                                                        <label htmlFor="residentialRadio">Residential</label>
                                                        <input
                                                            type="radio"
                                                            name="projectType"
                                                            id="commercialRadio" // Unique id
                                                            value="1" // Value representing this option
                                                            checked={parseInt(projectType) === 1} // Check against value
                                                            onChange={handleProjectTypeChange} // Call the function
                                                        />
                                                        <label htmlFor="commercialRadio">Commercial</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        {/*<div className="form-row col-2">*/}
                                        {/*    <div className="form-checkbox">*/}
                                        {/*        <div className="form-field checkbox">*/}
                                        {/*            <input*/}
                                        {/*                type="checkbox"*/}
                                        {/*                id="makePackagePrivate"*/}
                                        {/*                name="makePackagePrivate"*/}
                                        {/*                value="hidePackage"*/}
                                        {/*                checked={packagePrivate}*/}
                                        {/*                onChange={(e) => handlePrivatePackage(e)}*/}
                                        {/*            />*/}
                                        {/*            <label htmlFor="makePackagePrivate">Hide this package from the Pro*/}
                                        {/*                and Vendor feed.</label>*/}
                                        {/*            {packagePrivate ?*/}
                                        {/*                <p>*/}
                                        {/*                    <small>Only Property Owners will be able to see your*/}
                                        {/*                        package.</small>*/}
                                        {/*                </p>*/}
                                        {/*                : ''*/}
                                        {/*            }*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}


                                        <section className='form-details'>
                                            <h2 className="h4">Visibility</h2>
                                            <div className="form-row col-2">
                                                <div className="form-radio">
                                                    <div className="form-field radio">
                                                        <input
                                                            type="radio"
                                                            name="packageVisibility"
                                                            id="publicRadio"
                                                            value="public"
                                                            checked={!packagePrivate}
                                                            onChange={() => setPackagePrivate(false)}
                                                        />
                                                        <label htmlFor="publicRadio">Public View</label>
                                                        <input
                                                            type="radio"
                                                            name="packageVisibility"
                                                            id="privateRadio"
                                                            value="private"
                                                            checked={packagePrivate}
                                                            onChange={() => setPackagePrivate(true)}
                                                        />
                                                        <label htmlFor="privateRadio">Private View</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {packagePrivate && (
                                                <p>
                                                    <small>Only Property Owner will be able to see your
                                                        package.</small>
                                                </p>
                                            )}
                                        </section>
                                    </section>

                                    <section className="form-tags">
                                        <JobTags
                                            isTagSelected={isTagSelected}
                                            setTags={setTags}
                                            selectedTags={selectedTags}
                                        />
                                    </section>
                                    <nav id="step-controls">
                                        <div className="button-group">
                                            <button type="button" className="button-m button-tertiary" onClick={() => {
                                                navigate(`/survey`)
                                            }}><span className="text">Exit</span></button>
                                            <button type="button" className="button-m button-primary"
                                                    onClick={() => {
                                                        createNewPackage()
                                                    }}
                                            >
                                                <span className="text">Continue</span>
                                            </button>
                                        </div>
                                    </nav>
                                </form>
                            </div>
                        </>
                        :
                        <>
                            <BreadCrumbs
                                header1={'Home'}
                                link1={'survey'}
                                header2={'Package Details'}
                                link2={`window.location.reload(false);`}
                                isLink1Clickable={true}
                                isLink2Clickable={true}
                                isLink2Navigation={false}
                                header3={'Upload Files'}
                                link3={null}
                                setIsPackageUpdated={setIsPackageCreated}
                            />
                            <PageTitleWithSteps
                                currentStep1={''}
                                currentStep2={'current'}
                                header={'Create As-Built Package'}
                                subHeader={''}
                                step1Header={'Step One'}
                                step1SubHeader={'Fill out the Survey Package information.'}
                                step2Header={'Step Two'}
                                step2SubHeader={'Upload files related to the Survey Package.'}
                                completedStep1={true}
                                completedStep2={false}
                                isInactive={false}
                                isNewPackage={true}
                            />
                            <div className="form-container" id="create-package">
                                <form action="">
                                    <section className="form-covers">
                                        {/* <fieldset>
                                        <legend>Cover Picture</legend>
                                        <div className={`form-field file-input ${showWrongImageFormat || coverErrorMessage || coverErrorFileUploaded.length > 0 ? 'error' : ''}`}>
                                            <input type="file" id="cover-picture" className="file-input__input" onChange={(e) => handleFilesChange(e, 'cover')}/>
                                            <label htmlFor="cover-picture" className="button button-m button-tertiary">
                                                {getSvg('upload')}
                                                <span className="text">Choose file&hellip;</span>
                                            </label>
                                            <div className="file-input__filelist">{coverImageFileName}</div>
                                            { showWrongImageFormat || coverErrorFileUploaded.length > 0 && (
                                                <p className="validation-message">{getWrongFileFormatMessage('cover')}</p>
                                            )}
                                           
                                        </div>
                                    </fieldset>
                                    <fieldset>
                                        <legend>Scope of Work Video</legend>
                                        <div className={`form-field file-input ${!isValidScopeVideo || scopeOfWorkErrorMessage || scopeOfWorkErrorFileUpload.length > 0 ? 'error' : ''}`}>
                                            <input type="file" id="scope-of-work-video" className="file-input__input" onChange={changeHandlerScopeOfWork} />
                                            <label htmlFor="scope-of-work-video" className="button button-m button-tertiary">
                                                {getSvg('upload')}
                                                <span className="text">Choose file&hellip;</span>
                                            </label>
                                            <div className="file-input__filelist">{selectedFileScopeOfWorkName}</div>
                                            {showWrongImageFormat || scopeOfWorkErrorFileUpload.length > 0 && ( 
                                                <p className="validation-message">{getWrongFileFormatMessage('scope')}</p>
                                            )}
                                            
                                        </div>
                                    </fieldset> */}
                                    </section>

                                    <section className="form-cover outlined">
                                        <UploadSection
                                            header={'Cover Picture'}
                                            subHeader={''}
                                            divId={'upload-cover-picture'}
                                            fileType={'cover'}
                                            selectedFiles={selectedFileCoverImage}
                                            errorFileUploaded={coverErrorFileUploaded}
                                            handleFilesChange={handleFilesChange}
                                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                                            isMultiple={false}
                                        />
                                    </section>
                                    <section className="form-scope outlined">
                                        <UploadSection
                                            header={'Scope of Work'}
                                            subHeader={'video'}
                                            divId={'upload-scope-of-work'}
                                            fileType={'scopeofwork'}
                                            selectedFiles={selectedFileScopeOfWork}
                                            errorFileUploaded={scopeOfWorkErrorFileUpload}
                                            handleFilesChange={changeHandlerScopeOfWork}
                                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                                            isMultiple={false}
                                        />
                                    </section>
                                    <section className="form-pdfs outlined">
                                        <UploadSection
                                            header={'PDFs'}
                                            subHeader={'documents'}
                                            divId={'upload-pdfs'}
                                            fileType={'pdf'}
                                            selectedFiles={selectedFilePdf}
                                            errorFileUploaded={pdfErrorFileUploaded}
                                            handleFilesChange={handleFilesChange}
                                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                                            isMultiple={true}
                                        />
                                    </section>
                                    <section className="form-pictures outlined">
                                        <UploadSection
                                            header={'Still Pictures'}
                                            subHeader={''}
                                            divId={'upload-pictures'}
                                            fileType={'still'}
                                            selectedFiles={selectedFileStillPicture}
                                            errorFileUploaded={stillPictureErrorFileUploaded}
                                            handleFilesChange={handleFilesChange}
                                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                                            isMultiple={true}
                                        />
                                    </section>
                                    <section className="form-360-pictures outlined">
                                        <UploadSection
                                            header={'360'}
                                            subHeader={'pictures'}
                                            divId={'upload-360-pictures'}
                                            fileType={'360'}
                                            selectedFiles={selectedFileThreeSixty}
                                            errorFileUploaded={threeSixtyPictureErrorFileUploaded}
                                            handleFilesChange={handleFilesChange}
                                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                                            isMultiple={true}
                                        />
                                    </section>
                                    <section className="form-drone-pictures outlined">
                                        <UploadSection
                                            header={'Drone'}
                                            subHeader={'pictures'}
                                            divId={'upload-drone-pictures'}
                                            fileType={'drone'}
                                            selectedFiles={selectedFileDrone}
                                            errorFileUploaded={droneErrorFileUploaded}
                                            handleFilesChange={handleFilesChange}
                                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                                            isMultiple={true}
                                        />
                                    </section>
                                    <section className="form-cad outlined">
                                        <UploadSection
                                            header={'CAD'}
                                            subHeader={'file'}
                                            divId={'upload-cad'}
                                            fileType={'dwg'}
                                            selectedFiles={selectedDwg}
                                            errorFileUploaded={dwgErrorFileUploaded}
                                            handleFilesChange={handleFilesChange}
                                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                                            isMultiple={false}
                                        />
                                    </section>

                                    <nav id="step-controls">
                                        <div className="button-group">
                                            <div className='button-group fz-container fz-center block'>
                                                <button type='button' id="stillPictureBtn"
                                                        className="button-m button-primary" onClick={() => {
                                                    handleUpload('coverImage')
                                                }}>
                                                    Save
                                                </button>
                                                <span className='file-input__files'></span>
                                                <button
                                                    type='reset'
                                                    aria-label='Exit'
                                                    value='Exit'
                                                    className="button-m button-secondary"
                                                    onClick={() => {
                                                        window.location.reload(false);
                                                    }}
                                                >
                                                    Exit
                                                </button>
                                            </div>
                                        </div>
                                    </nav>
                                </form>
                            </div>
                        </>
                    }
                </div>
            </main>
            {showNoFundsMessage && (
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">You do not have funds to create a new package</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={() => {
                                        setShowNoFundsMessage(false);
                                        navigate('/survey')
                                    }}>
                                {getSvg('close')}
                            </button>
                        </div>
                        <div className="modal__content">
                            <p>If you want to crete a new package, <a href="" onClick={(e) => {
                                e.preventDefault();
                                navigate('/survey?type=newpackage');
                            }}>click here</a>.</p>
                        </div>
                    </div>
                </div>
            )}

            {showWarningContainerMessage && (
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4 error">Pictures or video format error.</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={() => setShowWarningContainerMessage(false)}>
                                {getSvg('close')}
                            </button>
                        </div>
                        <div className="modal__content">
                            <p>Please select pictures and video to upload in the correct format.</p>
                        </div>
                    </div>
                </div>
            )}
            {coverErrorMessage && (
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4 warning">Cover Picture is required.</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={() => setCoverErrorMessage(false)}>
                                {getSvg('close')}
                            </button>
                        </div>
                        <div className="modal__content">
                            <p>Select a cover picture and try it again.</p>
                        </div>
                    </div>
                </div>
            )}
            {scopeOfWorkErrorMessage && (
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4 warning">Scope of Work is required.</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={() => setScopeOfWorkErrorMessage(false)}>
                                {getSvg('close')}
                            </button>
                        </div>
                        <div className="modal__content">
                            <p>Select a Scope of Work video and try it again.</p>
                        </div>
                    </div>
                </div>
            )}
            {!showWrongImageFormat && uploadStarted ?
                <div className="modal-container">
                    <div className="modal">
                        {!showProcessing && uploadStarted ?
                            <>
                                <div className="modal__header">
                                    <h2 className="modal-title h4">File Upload Progress</h2>
                                </div>
                                <div className="modal__content">
                                    <div className="fz-block-sm">
                                        <AlertMessage
                                            messageHeader={process.env.REACT_APP_PROCESSING_MESSAGE_WAIT}
                                            messageBody={""}
                                            startDate={null}
                                            setShowWarningContainerMessage={setShowWarningContainerMessage}
                                            messageType={"informational"}
                                            messageBodyContinuation={null}
                                            showButton={false}
                                        />
                                    </div>
                                    <div className="file-uploads">
                                        <UploadingFilesSection
                                            title={'Cover Picture'}
                                            currentIndex={currentCoverImageIndex}
                                            selectedFiles={selectedFileCoverImage}
                                            fileProgressScopeOfWork={0}
                                            fileType={'all'}
                                        />

                                        {selectedFilePdf.length > 0 ?
                                            <section>
                                                <h5>PDFs</h5>
                                                {currentPdfIndex < selectedFilePdf.length && selectedFilePdf.length > 0 ?
                                                    currentPdfIndex > 0 ?
                                                        <p className="message"> Uploading
                                                            PDF {currentPdfIndex} of {selectedFilePdf.length}<span
                                                                className="loader loader--s"></span></p>
                                                        :
                                                        ''
                                                    :
                                                    <p className='message'>
                                                        All pdf files have been
                                                        uploaded!&nbsp; {getSvg('green-checkmark')}
                                                    </p>
                                                }
                                                {selectedFilePdf.length > 0 && currentPdfIndex === 0 && (
                                                    <p className="message">Preparing
                                                        PDF{selectedFilePdf.length > 1 ? 's' : ''} to be uploaded <span
                                                            className="loader loader--s"></span></p>)}
                                            </section>
                                            : ''
                                        }
                                        {selectedFileStillPicture.length > 0 && (
                                            <section>
                                                <h5>Still Pictures</h5>
                                                {currentStillPictureIndex < selectedFileStillPicture.length && selectedFileStillPicture.length > 0 ?
                                                    currentStillPictureIndex > 0 ?
                                                        <p className="message"> Uploading
                                                            picture {currentStillPictureIndex} of {selectedFileStillPicture.length}<span
                                                                className="loader loader--s"></span></p>
                                                        :
                                                        ''
                                                    :
                                                    <p className='message'>
                                                        All pictures have been
                                                        uploaded!&nbsp; {getSvg('green-checkmark')}
                                                    </p>
                                                }
                                                {selectedFileStillPicture.length > 0 && currentStillPictureIndex === 0 && (
                                                    <p className="message">Preparing
                                                        picture{selectedFileStillPicture.length > 1 ? 's' : ''} to be
                                                        uploaded <span className="loader loader--s"></span></p>)}
                                            </section>
                                        )}
                                        {selectedFileThreeSixty.length > 0 && (
                                            <section>
                                                <h5>360 Pictures</h5>
                                                {currentThreeSixtyIndex < selectedFileThreeSixty.length && selectedFileThreeSixty.length > 0 ?
                                                    currentThreeSixtyIndex > 0 && (
                                                        <p className="message">Uploading 360
                                                            picture {currentThreeSixtyIndex} of {selectedFileThreeSixty.length}<span
                                                                className="loader loader--s"></span></p>
                                                    )
                                                    :
                                                    <p className='message'>
                                                        All 360 pictures have been
                                                        uploaded!&nbsp; {getSvg('green-checkmark')}
                                                    </p>
                                                }
                                                {selectedFileThreeSixty.length > 0 && currentThreeSixtyIndex === 0 && (
                                                    <p className="message">Preparing
                                                        picture{selectedFileThreeSixty.length > 1 ? 's' : ''} to be
                                                        uploaded <span className="loader loader--s"></span></p>)}
                                            </section>
                                        )}
                                        {selectedFileDrone.length > 0 && (
                                            <section>
                                                <h5>Drone Pictures</h5>
                                                {currentIndex < selectedFileDrone.length && selectedFileDrone.length > 0 ? currentIndex > 0 && (
                                                    <p className="message">Uploading Drone
                                                        picture {currentIndex} of {selectedFileDrone.length}<span
                                                            className="loader loader--s"></span></p>
                                                )
                                                    :
                                                    <p className='message'>
                                                        All drone pictures have been
                                                        uploaded!&nbsp; {getSvg('green-checkmark')}
                                                    </p>
                                                }
                                                {selectedFileDrone.length > 0 && currentIndex === 0 && (
                                                    <p className="message">Preparing
                                                        picture{selectedFileDrone.length > 1 ? 's' : ''} to be
                                                        uploaded <span className="loader loader--s"></span></p>)}
                                            </section>
                                        )}
                                        {selectedDwg.length > 0 && (
                                            <section>
                                                <h5>CAD File</h5>
                                                {currentDwgIndex < selectedDwg.length && selectedDwg.length > 0 ?
                                                    currentDwgIndex > 0 && (
                                                        <p className="message">Uploading CAD
                                                            file {currentDwgIndex} of {selectedDwg.length}<span
                                                                className="loader loader--s"></span></p>
                                                    )
                                                    :
                                                    <p className='message'>
                                                        CAD file uploaded!&nbsp; {getSvg('green-checkmark')}

                                                    </p>
                                                }
                                                {selectedDwg.length > 0 && currentDwgIndex === 0 && (
                                                    <p className="message">Preparing CAD
                                                        file{selectedDwg.length > 1 ? 's' : ''} to be uploaded <span
                                                            className="loader loader--s"></span></p>)}
                                            </section>
                                        )}
                                        {selectedFileScopeOfWork.length > 0 ?
                                            <UploadingFilesSection
                                                title={'Scope of Work'}
                                                currentIndex={countScopeOfWorkVideoIndex}
                                                selectedFiles={selectedFileScopeOfWork}
                                                fileProgressScopeOfWork={fileProgressScopeOfWork}
                                                fileType={'video'}
                                            />
                                            : ''
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="modal__header">
                                    <h2 className="modal-title h4">Package successfully saved</h2>
                                </div>
                                <div className="modal__content">
                                    <p className="fz-align-center">{process.env.REACT_APP_PROCESSING_MESSAGE}.&nbsp; &nbsp;
                                        <span className="loader loader--s"></span></p>
                                </div>
                            </>
                        }
                    </div>
                </div>
                : ''
            }
            {showNotAllowed ?
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4 warning">You are not allowed to see this page.</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={() => navigate('/')}>
                                {getSvg('close')}
                            </button>
                        </div>
                        <div className="modal__content">
                            <p>You are not allowed to view this page or this package.</p>
                        </div>
                    </div>
                </div>
                : ''
            }

            {showModal && (
                <VideoModal
                    videoUrl={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Surveyor+-+Create+Package.mp4'}
                    onClose={handleCloseVideoModal}/>
            )
            }
        </div>
    )
}
