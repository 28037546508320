import React from 'react';
import {useNavigate} from 'react-router-dom';
import * as formValidator from '../../utils/formValidation';
import getSvg from '../../utils/getSvg';

function DisplayUserInfo(
    {
        pck,
        handleCopyToClipboard,
        linkToProfile=false,
        packageName='',
        isHomeOwner=false,
        leadtype=0,
    })
{
    const navigate = useNavigate();

    let fullAddress = '';
    if(pck.address !== ''){
        fullAddress = `${pck.address}`;
    }
    if(pck.address2.trim() !== ''){
        fullAddress = `${fullAddress}, ${pck.address2}<br />`;
    }else {
        fullAddress = `${fullAddress}<br />`;
    }
    if(pck.city !== ''){
        fullAddress = `${fullAddress}${pck.city}, `;
    }
    if(pck.state !== ''){
        fullAddress = `${fullAddress}${pck.state} `;
    }
    if(pck.zip !== ''){
        fullAddress = `${fullAddress}${pck.zip}`;
    }
    const formatAddress = (address) => {
        let formattedAddress = address.replace(/<br \/>/g, ', ');
        formattedAddress = formattedAddress.replace(',,', ',');
        formattedAddress = formattedAddress.replace(/&nbsp;/g, '');
        return formattedAddress;
    }

    return (
        <>
            {/* {linkToProfile ?  */}
            <div className='fz-block-sm'>
                <p>
                    <a
                        href="/"
                        onClick={(e) => {e.preventDefault(); navigate(`/portfolio/${pck.userId}/${leadtype}?packageName=${packageName}&ho=${isHomeOwner}`)}}
                    >
                        {pck.company}
                    </a><br />
                    <strong>{pck.firstName} {pck.lastName}</strong>
                </p>
            </div>
            {/* :
            <h5>{pck.company}</h5>
        } */}
            <div className="fz-white-space">
                <span dangerouslySetInnerHTML={{ __html: fullAddress }} />&nbsp;&nbsp;
                <button
                    title="Copy!"
                    className="button button-icon button-xs"
                    onClick={() => handleCopyToClipboard(formatAddress(fullAddress))}
                >
                    {getSvg('copy')}
                </button>
            </div>

            <p>
                {formValidator.formatPhoneNumber(pck.phone)}
                &nbsp;&nbsp;
                <span className="copy">
                <button type="button" className="button-xs button-icon" title="Copy Phone Number" onClick={() => handleCopyToClipboard(formValidator.formatPhoneNumber(pck.phone))}>
                    {getSvg('copy')}
                </button>
            </span>
            </p>
            <p>
                <a href={`mailto:${pck.email}`} title="Copy email address">{pck.email}</a>
                &nbsp;&nbsp;
                <span className="copy">
                <button type="button" className="button-xs button-icon" title="Copy Email Address" onClick={() => handleCopyToClipboard(pck.email)}>
                    {getSvg('copy')}
                </button>
            </span>
            </p>
            <p>
                <a href={pck.website} target="_blank" rel="noopener noreferrer" title="Visit website">{pck.website}</a>
            </p>
            <p>
                License: {pck.license}
                &nbsp;&nbsp;
                <span className="copy">
                <button type="button" className="button-xs button-icon" title="Copy License" onClick={() => handleCopyToClipboard(pck.license)}>
                    {getSvg('copy')}
                </button>
            </span>
            </p>
        </>
    );
}

export default DisplayUserInfo;
