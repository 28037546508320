import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminToolsList from './pages/tools/AdminToolsList';
import ApproveProAccount from './pages/libs/ApproveProAccount';
import Courses from './pages/libs/Courses';
import CreateProVendorAccount from './pages/Pros/CreateProVendorAccount';
import CreateSurveyorAccounts from './pages/libs/CreateSurveyorAccounts';
import CreateNewPackage from './pages/Surveyors/SurveyorCreateNewPackage';
import DeletePackages from './pages/tools/DeletePackages';
import DeleteVideoSubmissions from './pages/tools/DeleteVideoSubmissions';
import DwgGallery from './pages/libs/DwgGallery';
import EditPackagePictures from './pages/libs/EditPackagePictures';
import EditPackagePicturesFolders from './pages/libs/EditPackagePicturesFolders';
import FolderApp from './pages/libs/FolderApp';
import FolderCreation from './pages/libs/FolderCreation';
import ForgotPassword from './pages/login/ForgotPassword';
import FreeLead from './pages/HomeOwners/FreeLead';
import HireSurveyor from './pages/HomeOwners/HireSurveyor';
import HomeOwnersFeed from './pages/HomeOwners/HomeOwnersFeed';
import HomeOwnersPackageDetails from './pages/HomeOwners/HomeOwnersPackageDetails';
import HomeOwnersResponses from './pages/HomeOwners/HomeOwnersResponses';
import LeadUnderway from './pages/HomeOwners/LeadUnderway';
import Login from './pages/login/Login';
import MediaGallery from './pages/Pros/MediaGallery';
import NewPassword from './pages/login/NewPassword';
import PackageDetails from './pages/libs/PackageDetails';
import PdfGallery from './pages/libs/PdfGallery';
import PhotoFoldersGallery from './pages/libs/PhotoFoldersGallery';
import PhotoGallery from './pages/libs/PhotoGallery';
import Portfolio from './pages/Pros/Portfolio';
import ProFeedFree from './pages/Pros/ProFeedFree';
import ProFeedPaid from './pages/Pros/ProFeedPaid';
import ProNetwork from './pages/libs/ProNetwork';
import ProMedia from './pages/Pros/ProMedia';
import ProPackageDetails from './pages/Pros/ProPackageDetails';
import ProFreePortfolios from './pages/Pros/ProFreePortfolios';
import ProFreeTestimonials from './pages/Pros/ProFreeTestimonials';
import Profiles from './pages/libs/Profiles';
import ProPortfolios from './pages/Pros/ProPortfolios';
import ProReplies from './pages/Pros/ProReplies';
import ProTestimonials from './pages/Pros/ProTestimonials';
import ProVideosGallery from './pages/Pros/ProVideosGallery';
import RejectedUsers from './pages/tools/RejectedUsers';
import ShareContacts from './pages/libs/ShareContacts';
import SingleLeadHomeOwner from './pages/HomeOwners/SingleLeadHomeOwner';
import SurveyorEditPackage from './pages/Surveyors/SurveyorEditPackage';
import SurveyorPackageFeed from './pages/Surveyors/SurveyorPackageFeed';
import SurveyorPackageDetails from './pages/Surveyors/SurveyorPackageDetails';
import ThreeSixtyComponent from './pages/libs/ThreeSixtyComponent';
import VerifyAccount from './pages/HomeOwners/VerifyAccount';
import VideoPlayer from './pages/libs/VideoPlayer';
import SurveyorFolderStructure from './pages/Surveyors/SurveyorFolderStructure';
import SurveyorFolderStructureEdit from './pages/Surveyors/SurveyorFolderStructureEdit';
import Viewer from './pages/libs/Viewer';
import "react-image-gallery/styles/css/image-gallery.css";
import PayPalPage from './pages/Surveyors/PayPalPage';
import PlanAndPicture from "./pages/libs/plan-and-picture/PlanAndPicture";
import PlanAndPictureList from "./pages/libs/PlanAndPictureList";
import { PrivateRoute } from "./pages/libs/PrivateRoute";
import { NotAllowed } from './pages/libs/NotAllowed';
import CreatePackagePictures from "./pages/libs/CreatePackagePicturesFolders";
import {FeatureFlagRoute} from "./pages/libs/FeatureFlagRoute";
import ProNetworkDetails from './pages/libs/ProNetworkDetails';


function App() {
  const isAdmin = sessionStorage.getItem('isAdmin');
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/approveaccount/:recordid' element={<ApproveProAccount />} />
        <Route path='/courses/:userId' element={<PrivateRoute to={'/not-allowed'} userType={process.env.REACT_APP_SURVEYOR_USER_TYPE_TO_BE_CHECKED}><Courses /></PrivateRoute>} />
        <Route path='/createaccount' element={<CreateProVendorAccount />} />
        <Route path='/contacts/:userId/:type/:packageName' element={<ShareContacts />} />
        <Route path='/delete' element={<PrivateRoute><DeletePackages /></PrivateRoute>} />
        <Route path='/deletesubmissions' element={<DeleteVideoSubmissions />} />
        <Route path='/details' element={<PrivateRoute><PackageDetails /></PrivateRoute>} />
        <Route path='/dwg/:packageName/:leadtype' element={<DwgGallery />} />
        <Route path='/edit/:packageName' element={<PrivateRoute><SurveyorEditPackage /></PrivateRoute>} />
        <Route path='/editpackage/:packageName' element={<PrivateRoute><SurveyorEditPackage /></PrivateRoute>} />
        <Route path='/editpackagepictures/:packageName/:pictureType' element={<EditPackagePictures />} />
        <Route path='/editpackagefolderspictures/:packageName/planandpicture/:pdfId' element={<PrivateRoute><PlanAndPicture /></PrivateRoute>} />
        <Route path='/editpackagefolderspictures/:packageName' element={<PrivateRoute><EditPackagePicturesFolders /></PrivateRoute>} />
        <Route path='/createpackagefolderspictures/:packageName' element={<PrivateRoute><CreatePackagePictures /></PrivateRoute>} />
        <Route path='/folders' element={<FolderApp />} />
        <Route path='/foldercreation' element={<FolderCreation />} />
        <Route path='/forgotpassword/:referencenumber' element={<ForgotPassword />} />
        <Route path='/galleries/:packageName/:mediaType/:leadtype' element={<PhotoFoldersGallery />} />
        <Route path='/gallery/:packageName/:pageName/:leadtype' element={<PhotoGallery />} />
        <Route path='/hiresurveyor' element={<HireSurveyor />} />
        <Route path='/leads/:leadId/:leadtype' element={<SingleLeadHomeOwner />} />
        <Route path='/login' element={<Login />} />
        <Route path='/media-gallery/:userId' element={<MediaGallery />} />
        <Route path='/mynetwork' element={<ProNetwork />} />
        <Route path='/mynetwork/pro/:userId' element={<ProNetworkDetails />} />
        <Route path='/newlead' element={<FreeLead />} />
        <Route path='/newpackage/:packageName' element={<PrivateRoute to={'/not-allowed'} userType={process.env.REACT_APP_SURVEYOR_USER_TYPE_TO_BE_CHECKED}><CreateNewPackage /></PrivateRoute>} />
        <Route path='/newpassword' element={<NewPassword />} />
        <Route path='/packagemedia/:packageName' element={<PrivateRoute userType={process.env.REACT_APP_SURVEYOR_USER_TYPE_TO_BE_CHECKED}><SurveyorFolderStructure /></PrivateRoute>} />
        <Route path='/packagemediaedit/:packageName' element={<PrivateRoute><SurveyorFolderStructureEdit /></PrivateRoute>} />
        <Route path='/pdf/:packageName/:leadtype' element={<PdfGallery />} />
        <Route path='/portfolio/:userId/:leadtype' element={<Portfolio />} />
        <Route path='/prodetails/:packageName' element={<ProPackageDetails />} />
        <Route path='/profeedfree' element={<ProFeedFree />} />
        <Route path='/profeed' element={<ProFeedPaid />} />
        <Route path='/profile/:userType' element={<PrivateRoute><Profiles /></PrivateRoute>} />
        <Route path='/prolibrary' element={<ProVideosGallery />} />
        <Route path='/property/:packageName' element={<HomeOwnersFeed />} />
        <Route path='/proportfolios' element={<ProPortfolios />} />
        <Route path='/profreeportfolios/:userId/:leadId' element={<ProFreePortfolios />} />
        <Route path='/proreplies' element={<ProReplies />} />
        <Route path='/profreetestimonials/:userId/:leadId' element={<ProFreeTestimonials />} />
        <Route path='/promedia/:type' element={<ProMedia />} />
        <Route path='/rejects' element={<RejectedUsers />} />
        <Route path='/responses/:packageName/:leadtype' element={<HomeOwnersResponses />} />
        <Route path='/showdetails/:packageName/:leadtype' element={<HomeOwnersPackageDetails />} />
        <Route path='/showprodetails/:packageName/:leadtype' element={<HomeOwnersPackageDetails proMode={true} />} />
        <Route path='/signup' element={<CreateSurveyorAccounts />} />
        <Route path='/survey' element={<PrivateRoute><SurveyorPackageFeed /> </PrivateRoute>} />
        <Route path='/survdetails/:packageName' element={<PrivateRoute><SurveyorPackageDetails /></PrivateRoute>} />
        <Route path='/testimonials' element={<ProTestimonials />} />
        <Route path='/threesixty/:packageName/:folder/:picture' element={<ThreeSixtyComponent />} />
        <Route path='/tools' element={<AdminToolsList />} />
        <Route path='/underway' element={<LeadUnderway />} />
        <Route path='/verify/:referenceNumber' element={<VerifyAccount />} />
        <Route path='/videoplayer/:id/:packageName' element={<VideoPlayer />} />
        <Route path='/viewer' element={<Viewer />} />
        <Route path='/paypal' element={<PayPalPage />} />
        <Route path='/plan-and-picture/:packageName/list' element={<PlanAndPictureList />} />
        <Route path='/plan-and-picture/:packageName/:pdfId' element={<PlanAndPicture />} />
        <Route path='/not-allowed' element={<NotAllowed />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
