import React from 'react';
import getSvg from '../../utils/getSvg';

const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    if (totalPages <= 1) return null;

    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handleClick = (page) => {
        onPageChange(page);
    };

    return (
        <div className="pagination">
            <div className="previous">
                <div className="button-group">
                    <button
                        type="button"
                        className={`button-s ${currentPage === 1 ? 'button-disabled' : 'button-tertiary'} button-icon`}
                        title="Previous Page"
                        disabled={currentPage === 1}
                        onClick={() => onPageChange(currentPage - 1)}
                    >
                        {getSvg('chevron-left')}
                    </button>
                </div>
            </div>
            <div className="pages">
                <div className="button-group">
                    {pageNumbers.map((number) => (
                        <button
                            key={number}
                            type="button"
                            className={`button-s ${number === currentPage ? 'button-primary' : 'button-tertiary'} button-icon`}
                            onClick={() => handleClick(number)}
                        >
                            <span className="text">{number}</span>
                        </button>
                    ))}
                </div>
            </div>
            <div className="next">
                <div className="button-group">
                    <button
                        type="button"
                        className={`button-s ${currentPage === totalPages ? 'button-disabled' : 'button-tertiary'} button-icon`}
                        title="Next Page"
                        disabled={currentPage === totalPages}
                        onClick={() => onPageChange(currentPage + 1)}
                    >
                        {getSvg('chevron-right')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
