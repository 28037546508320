import React, { useState } from 'react';
import getSvg from '../../utils/getSvg';

export const SelectSendTypeContactPropertyOwnerModal = ({ onClose, onSubmit }) => {
    const [selectedOption, setSelectedOption] = useState('profile-video');

    return (
        <div className="modal-container">
            <div className="modal">
                <div className="modal__header">
                    <h2 className="modal-title h4">Contact Property Owner</h2>
                    <button
                        type="button"
                        className="button-tertiary button-s button-icon modal-close"
                        onClick={onClose}
                    >
                        {getSvg('close')}
                    </button>
                </div>
                <div className="modal__content">
                    <p>
                        Choose how to connect with the Property Owner
                    </p>

                    <div className="form-field radio">
                        <div className="radio-group">
                            <div className="radio-option">
                                <input
                                    type="radio"
                                    id="profile-video"
                                    name="contact-option"
                                    value="profile-video"
                                    checked={selectedOption === 'profile-video'}
                                    onChange={(e) => setSelectedOption(e.target.value)}
                                />
                                <div className="radio-label">
                                    <label htmlFor="profile-video">FREE: Send profile and video</label>
                                    <p className="helper-text">Personalized videos are 4x more likely to get a call back</p>
                                </div>
                            </div>
                            <div className="radio-option">
                                <input
                                    type="radio"
                                    id="profile-only"
                                    name="contact-option"
                                    value="profile-only"
                                    checked={selectedOption === 'profile-only'}
                                    onChange={(e) => setSelectedOption(e.target.value)}
                                />
                                <div className="radio-label">
                                    <label htmlFor="profile-only">PAID: Send profile only</label>
                                    <p className="helper-text">No video required</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal__action">
                    <div className="button-group">
                        <button
                            type="button"
                            className="button-m button-primary"
                            onClick={() => onSubmit(selectedOption)}
                        >
                            <span className="text">Next</span>
                        </button>
                        <button
                            type="button"
                            className="button-m button-secondary"
                            onClick={onClose}
                        >
                            <span className="text">Close</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}; 
