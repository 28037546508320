/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Axios from 'axios';
import Header from './Header'
import getSvg from '../../utils/getSvg';
import * as formValidation from '../../utils/formValidation';
import {copyToClipBoard} from "../../utils/copyToClipBoard";
import PageTitle from "./PageTitle";
import VideoModal from "./VideoModal";
import Pagination from "./Pagination";
import axiosInstance from "../../utils/axiosInstance";

export default function ProNetwork() {
    const navigate = useNavigate();
    const [pros, setPros] = useState([]);
    const [proSubmissions, setProSubmissions] = useState([]);
    const [showProInfo, setShowProInfo] = useState(false);
    const [singlePro, setSinglePro] = useState([]);
    const surveyorId = sessionStorage.getItem('userId');
    const [showLoader, setShowLoader] = useState(false);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const handleGoToLogin = () => navigate('/login');

    const [showModal, setShowModal] = React.useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [limit] = useState(10);

    const getPros = (page = 1) => {
        setShowLoader(true);
        try {
            axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/api/pros/get-pros`, {
                surveyorId: surveyorId,
                page: page,
                limit: limit
            }).then((response) => {
                setPros(response.data.data);
                setTotalItems(response.data.total);
                setShowLoader(false);
            }).catch(function (error) {
                setShowLoader(false);
            })
        } catch (error) {
            setShowLoader(false);
        }
    }

    const goToPackage = (packageName) => {
        navigate(`/survdetails/${packageName}`);
    }

    const handleShowVideoModal = () => {
        setShowModal(true);
    };

    const handleCloseVideoModal = () => {
        setShowModal(false);
    };


    const getPackageAddress = (pro) => {
        let fullAddress = `${pro.packageAddress}`;
        if (pro.packageAddress2 !== null && pro.packageAddress2.trim() !== '') {
            fullAddress = `${fullAddress}, ${pro.packageAddress2}, `;
        } else {
            fullAddress = `${fullAddress}, `;
        }
        fullAddress = `${fullAddress}${pro.packageCity}, `;
        fullAddress = `${fullAddress}${pro.packageState} `;
        fullAddress = `${fullAddress}${pro.packageZip}`;
        return fullAddress;
    }

    useEffect(() => {
        if (isLoggedIn !== 'true') {
            handleGoToLogin();
        } else {
            getPros(currentPage);
        }
    }, []);

    const getAddresses = (pck) => {
        let fullAddress = '';
        if (pck.packageAddress !== '') {
            fullAddress = `${pck.packageAddress}`;
        }
        if (pck.packageAddress2.trim() !== '') {
            fullAddress = `${fullAddress}, ${pck.packageAddress2}, `;
        } else {
            fullAddress = `${fullAddress}, `;
        }
        if (pck.packageCity !== '') {
            fullAddress = `${fullAddress}${pck.packageCity}, `;
        }
        if (pck.packageState !== '') {
            fullAddress = `${fullAddress}${pck.packageState} `;
        }
        if (pck.packageZip !== '') {
            fullAddress = `${fullAddress}${pck.packageZip}`;
        }
        return fullAddress;
    }

    const getProSubmissions = (Id) => {
        setShowLoader(true);
        try {
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/get-pro-submissions`, {userId: Id}).then((response) => {
                setProSubmissions(response.data);
                setShowLoader(false);
            }).catch(function (error) {
                setShowLoader(false);
                console.log(error);
            })
        } catch (error) {
            setShowLoader(false);
        }
    }

    const handleCopyToClipboard = (textToCopy) => {
        copyToClipBoard(textToCopy)
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        getPros(newPage);
    };

    return (
        <div id="fazzad-site-container">
            <Header
                mode='default'
            />
            <main id="fazzad-main">
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                            <>
                                <li>
                                    <a href='' onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/survey')
                                    }}>Home</a>
                                    <span className="separator">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                </li>
                                <li aria-current="page">
                                    <span>My network of Pros & Vendors</span>
                                </li>
                            </>
                        </ol>
                    </nav>

                    <div className={'fz-fd-row fz-jc-space-between fz-ai-center'}>
                        <div id="package-title" className="nowrap">
                            <div>
                                <h1 className="h3" style={{marginBottom: '0.7rem'}}>My network of Pros & Vendors</h1>
                                <p>Click the name to view contact details and projects they reached out to.</p>
                            </div>
                        </div>

                        <div className="fz-fd-row fz-jc-flex-end">
                            <a className="fz-info" onClick={() => handleShowVideoModal()}>
                                        <span className="icon">
                                    {getSvg('info')}
                                        </span>
                                <span className="text">Info</span>
                            </a>
                        </div>
                    </div>
                    {showLoader && (<span className="loader loader--l"></span>)}

                    <div className="pro-vendor__container" style={{marginBottom: '2rem'}}>
                    <div className="pro-vendor">
                            <div className="content">
                                {pros?.map((pro, index) => (
                                    <React.Fragment key={index}>
                                        <div className="inline">
                                            <div className="inline-title">
                                                <h2 className="h5">
                                                    {pro.totalProjects > 0 ? (
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                            navigate(`/mynetwork/pro/${pro.userId}`);
                                                        }}>
                                                            {pro.firstName} {pro.lastName} {pro.userType === '500001' ? '(Surveyor)' : ''}
                                                        </a>
                                                    ) : (
                                                        <span>{pro.firstName} {pro.lastName} {pro.userType === '500001' ? '(Surveyor)' : ''}</span>
                                                    )}
                                                </h2>
                                                <small>{pro.company}</small>
                                                <small title="Projects contacted by this Pro/Vendor">
                                                    Projects: <strong>{pro.totalProjects}</strong> ({pro.videoProjects} video, {pro.contactProjects} contact
                                                    info)
                                                </small>
                                            </div>
                                            <div className="inline-buttons">
                                                <span className="inline-title">
                                                    <button 
                                                        title={pro.email}
                                                        className="cw_button cw_button-call" 
                                                        onClick={() => handleCopyToClipboard(pro.email)}
                                                    >
                                                        <svg width="16px" height="16px" viewBox="0 0 92 75" xmlns="http://www.w3.org/2000/svg" fill="currentColor" style={{fillRule:'evenodd', clipRule:'evenodd', strokeLinejoin:'round', strokeMiterlimit:2}}>
                                                            <path d="M0.286,15.092c-0.314,0.798 -0.365,1.66 -0.173,2.467c-0.087,1.832 -0.113,3.972 -0.113,6.52l-0,26.57c-0,7.992 0.255,11.959 1.809,15.012l0.001,0c1.592,3.125 4.132,5.666 7.257,7.257c3.053,1.555 7.021,1.81 15.012,1.81l43.176,0c7.992,0 11.96,-0.255 15.012,-1.81c3.125,-1.591 5.665,-4.132 7.257,-7.257c1.556,-3.053 1.811,-7.02 1.811,-15.012l-0,-26.57c-0,-2.548 -0.026,-4.688 -0.113,-6.52c0.191,-0.807 0.14,-1.669 -0.174,-2.467c-0.247,-2.563 -0.706,-4.42 -1.524,-6.025c-1.592,-3.125 -4.132,-5.665 -7.256,-7.257c-0,0 -0,-0.001 -0.001,-0.001c-3.052,-1.554 -7.02,-1.809 -15.012,-1.809l-43.176,0c-7.991,0 -11.959,0.255 -15.012,1.809c-3.125,1.594 -5.664,4.133 -7.257,7.258c-0.818,1.605 -1.276,3.462 -1.524,6.025Zm82.745,9.488l-27.364,19.155c-3.383,2.368 -5.148,3.424 -6.988,3.882c-1.978,0.493 -4.046,0.493 -6.024,-0c-1.839,-0.458 -3.604,-1.514 -6.987,-3.882l-27.365,-19.155l-0,26.069c-0,5.961 -0.255,8.965 0.906,11.243c0.796,1.562 2.065,2.832 3.627,3.628l0.001,-0c2.277,1.161 5.281,0.905 11.242,0.905l43.176,0c5.962,0 8.966,0.256 11.243,-0.905l0.001,-0c1.561,-0.796 2.831,-2.066 3.627,-3.628c1.16,-2.278 0.905,-5.281 0.905,-11.243l0,-26.069Zm-0.289,-9.933c-0.137,-0.667 -0.336,-1.26 -0.616,-1.81l0,-0.001c-0.796,-1.562 -2.066,-2.831 -3.628,-3.627c-2.277,-1.161 -5.281,-0.906 -11.243,-0.906l-43.176,-0c-5.961,-0 -8.965,-0.255 -11.243,0.906c-1.562,0.796 -2.831,2.065 -3.627,3.627c-0.281,0.551 -0.479,1.144 -0.617,1.811l31.838,22.287c2.108,1.475 3.086,2.34 4.233,2.626l0.001,0c0.658,0.165 1.348,0.165 2.007,0c1.147,-0.286 2.126,-1.151 4.234,-2.626l31.837,-22.287Z"/>
                                                        </svg>
                                                    </button>
                                                    <small>copy</small>
                                                </span>
                                                
                                                <span className="inline-title">
                                                    <button 
                                                        title={formValidation.formatPhoneNumber(pro.phone)}
                                                        className="cw_button cw_button-call" 
                                                        onClick={() => window.location.href = `tel:${pro.phone}`}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" width="16px" height="16px" fill="currentColor">
                                                            <path d="M266.67-40q-27 0-46.84-19.83Q200-79.67 200-106.67v-746.66q0-27 19.83-46.84Q239.67-920 266.67-920h426.66q27 0 46.84 19.83Q760-880.33 760-853.33v746.66q0 27-19.83 46.84Q720.33-40 693.33-40H266.67Zm0-166.67v100h426.66v-100H266.67Zm213.45 83.34q14.21 0 23.71-9.62t9.5-23.83q0-14.22-9.61-23.72-9.62-9.5-23.84-9.5-14.21 0-23.71 9.62-9.5 9.61-9.5 23.83 0 14.22 9.61 23.72 9.62 9.5 23.84 9.5Zm-213.45-150h426.66v-480H266.67v480Zm0-546.67h426.66v-33.33H266.67V-820Zm0 613.33v100-100Zm0-613.33v-33.33V-820Z"/>
                                                        </svg>
                                                    </button>
                                                    <small>call</small>
                                                </span>

                                                {pro.website && (
                                                    <span className="inline-title">
                                                        <button 
                                                            className="cw_button cw_button-call" 
                                                            title={pro.website}
                                                            onClick={() => window.open(pro.website, '_blank')}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="currentColor">
                                                                <path d="M838-65 720-183v89h-80v-226h226v80h-90l118 118-56 57ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 20-2 40t-6 40h-82q5-20 7.5-40t2.5-40q0-20-2.5-40t-7.5-40H654q3 20 4.5 40t1.5 40q0 20-1.5 40t-4.5 40h-80q3-20 4.5-40t1.5-40q0-20-1.5-40t-4.5-40H386q-3 20-4.5 40t-1.5 40q0 20 1.5 40t4.5 40h134v80H404q12 43 31 82.5t45 75.5q20 0 40-2.5t40-4.5v82q-20 2-40 4.5T480-80ZM170-400h136q-3-20-4.5-40t-1.5-40q0-20 1.5-40t4.5-40H170q-5 20-7.5 40t-2.5 40q0 20 2.5 40t7.5 40Zm34-240h118q9-37 22.5-72.5T376-782q-55 18-99 54.5T204-640Zm172 462q-18-34-31.5-69.5T322-320H204q29 51 73 87.5t99 54.5Zm28-462h152q-12-43-31-82.5T480-798q-26 36-45 75.5T404-640Zm234 0h118q-29-51-73-87.5T584-782q18 34 31.5 69.5T638-640Z"/>
                                                            </svg>
                                                        </button>
                                                        <small>visit</small>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        {index < pros.length - 1 && <hr className="separator"/>}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>

                    <Pagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={limit}
                        onPageChange={handlePageChange}
                    />
                </div>
            </main>
            {showProInfo && (
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">{singlePro.firstName} {singlePro.lastName}</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close"
                                    onClick={() => {
                                        setShowProInfo(false);
                                    }}>
                                {getSvg('close')}
                            </button>
                        </div>
                        <div className="modal__content">
                            <p>Company:&nbsp;{singlePro.company}</p>
                            <p>License #:&nbsp;{singlePro.license}</p>
                            <p>
                                Email:&nbsp;{singlePro.email}&nbsp;
                                <button
                                    title="Copy!"
                                    className="button button-icon button-xs"
                                    onClick={() => handleCopyToClipboard(singlePro.email)}
                                >
                                    {getSvg('copy')}
                                </button>
                            </p>
                            <p>
                            Phone:&nbsp;{formValidation.formatPhoneNumber(singlePro.phone)}&nbsp;
                                <button
                                    title="Copy!"
                                    className="button button-icon button-xs"
                                    onClick={() => handleCopyToClipboard(singlePro.phone)}
                                >
                                    {getSvg('copy')}
                                </button>
                            </p>
                            {proSubmissions.length > 0 && (
                                <>
                                    <hr/>
                                    <div className="table-container">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>Projects contacted:</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {proSubmissions.map((submission, index) => {
                                                return (
                                                    <tr key={`tr_${index}`}>
                                                        <td key={`td_${index}`} className="fz-break-all">
                                                            <a href="" onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate(`/survdetails/${submission.packageName}`)
                                                            }}>{getAddresses(submission)}</a>
                                                            &nbsp;
                                                            <button
                                                                title="Copy!"
                                                                className="button button-icon button-xs"
                                                                onClick={() => handleCopyToClipboard(getAddresses(submission))}
                                                            >
                                                                {getSvg('copy')}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {showModal && (
                <VideoModal
                    videoUrl={'https://fazzad-courses.s3.us-west-1.amazonaws.com/tutorials/Surveyor+-+My+Network.mp4'}
                    onClose={handleCloseVideoModal}/>
            )
            }
        </div>
    )
}
